.container {
  max-width: 1200px;
}

h1, h2 {
  margin-bottom: 10px;
}

ul {
  padding-left: 0;
}

p {
  line-height: 1.6;
}

button {
  padding: 10px 20px;
  font-size: 14px;
}
