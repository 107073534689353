.contact-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
  }
  
  .contact-form .form-control {
    border-radius: 5px;
    border: 1px solid #ced4da;
  }
  
  .contact-form .btn {
    background-color: #22a0bf;
    color: white;
    border: none;
  }
  
  .contact-form .btn:hover {
    background-color: #1d8ca1;
  }
  
  .list-unstyled li {
    font-size: 16px;
  }
  
  .list-unstyled li svg {
    color: #22a0bf;
    font-size: 20px;
  }
  