.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




/* mycss */

*:focus {
  outline: none;
}

.textdecoration {
  text-decoration: dotted;
}

.body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #f4f4f4; */
  background-color: black;
  overflow-x: hidden;
  max-width: 100%;
}



.no-outline {
  outline: none !important;
  margin: 0px;
}

/* Navbar Start For DeskTop */
.navbar {
  background-color: white;
  padding: 15px 0;
}



.navbar-brand {
  color: white;
  font-size: 24px;
}

.logoimg {
  width: 80px;
}

.mobilelogo {
  width: 63px;
}

.navbar-toggler-icon {
  color: white;
}

.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-nav {
  margin-left: 0;
}

.nav-item {
  text-align: left;
}

.nav-link {
  color: black;
  font-size: 16px;
  transition: color 0.3s;
  font-weight: lighter;
}

.nav-link:hover {
  color: #003C71;
}

/* Mega Menu Styles */
.mega-menu {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

}

.mega-menu-show {
  display: block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;

}

.content {
  display: none;
  color: black;
}

.show-content {
  display: block;
  color: black;
  text-align: left;

}

.mega-menu-content {
  padding: 20px;
}



.mega-menu-column {
  margin-bottom: 20px;
  /* width: 20%; */
}

.mega-menu-link {
  display: block;
  color: #333;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.mega-menu-link:hover {
  color: #003C71;
}

.nav-link.my-btn {
  color: #003C71;
  background-color: transparent;
  border: 1px solid #003C71;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.my-btn {
  color: #003C71;
  
  background-color: transparent;
  border: 1px solid #003C71;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.my-btn:hover {
  background-color: #003C71;
  border-radius: 5px;
  color: #fff;
}

.my-drop-btn{
  color: #003C71;
  background-color: transparent;
  border: 3px solid #003C71;
  padding: 0px 0px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;

  width: 55px;
  height: 45px;
}

.my-drop-btn:hover{
  background-color: #003C71;
  border-radius: 5px;
  color: #fff;
  
}

.custom-accordion-button {
  background-color: transparent;
  color: #000; /* Adjust text color */
  border: none;
  padding: 1rem; /* Adjust padding as needed */
  font-size: 1rem; /* Adjust font size */
  text-align: left; /* Adjust alignment */
  cursor: pointer;
}

.custom-accordion-button:focus {
  outline: none; /* Remove outline on focus if needed */
}


.my-btn1 {
  color: #fff;
  background-color: orangered;
  border: 1px solid orangered;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.my-btn1:hover {
  background-color: transparent;
  border-radius: 5px;
  color: orangered;
}

.nav-link.my-btn:hover {
  background-color: #003C71;
  border-radius: 5px;
  color: #fff;
}





.displaynone {
  display: none;
}

.mobilenone {
  display: block;
}

.my-btn-roundad {
  color: #003C71;
  background-color: transparent;
  border: 1px solid #003C71;
  padding: 10px 20px;
  border-radius: 50px;
  transition: background-color 0.3s, color 0.3s;
}

.my-btn-roundad:hover {
  background-color: #003C71;
  border-radius: 50px;
  color: #fff;
}

/* Adjustments for Login Button and Mobile Layout */
@media screen and (max-width: 600px) {

  /* .logoimg {
    width: 80px;
    height: 35px;
  } */

  .displaynone {
    display: block;
  }

  .mobilenone {
    display: none;
    border: 0px;
  }
}






/* Navbar End For DeskTop */

/* banner start */
.parent-container {
  overflow: hidden;
  border: 0px solid #003C71;
}




.banner-slider-container {
  position: relative;
  margin: 0px;
  max-width: 100%;
  border: 0px;
  height: auto;
  overflow-x: visible;
  /* border: 1px solid #003C71; */
}


.prev-button,
.next-button {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: transparent;
  color: #003C71;
  padding: 10px 15px;
  border: 1px solid #003C71;
  border-radius: 5px;
  cursor: pointer;
}

/* .prev-button, */
.next-button:hover {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: #003C71;
  color: white;
  padding: 10px 15px;
  border: 1px solid #003C71;
  border-radius: 5px;
  cursor: pointer;
}

.prev-button:hover {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: #003C71;
  color: white;
  padding: 10px 15px;
  border: 1px solid #003C71;
  border-radius: 5px;
  cursor: pointer;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}


/* src/CardSlider.css */
.card-slider {
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.slider-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.card-slide {
  flex: 0 0 100%;
  box-sizing: border-box;
  padding: 10px;
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.card-slide.active {
  opacity: 1;
}

button {
  margin-top: 10px;
}

div.scroll-container {
  background-color: transparent;
  overflow: auto;
  white-space: nowrap;
  /* padding: 10px; */
}

div.scroll-container::-webkit-scrollbar {
  display: none;
}

div.scroll-container img {
  background-color: white;
  /* padding: 3px;
  margin: 2px; */
}

footer {
  background-color: white;
  color: black;
  padding: 8px 0;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin: 0 20px;
}

.footer-logo img {
  width: 150px;
  /* Adjust as needed */
}

.footer-info p {
  margin: 0 0 10px;
  line-height: 1.6;
}

.footer-links ul {
  /* list-style: none; */
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 8px;
}

.footer-links a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #003C71;
}

.footer-social a {
  display: inline-block;
  margin-right: 10px;
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-social a:hover {
  color: #003C71;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #888;
}

.footer-bottom a {
  color: #003C71;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-bottom a:hover {
  color: #008ba3;
}


/* chat popup start */
#chatPopup {
  /* display: none; */
  position: fixed;
  bottom: 20px;
  justify-content: center;
  align-items: center;
  right: 5px;
  /* border: 2px solid red; */
  /* box-shadow: -1px 0 1px 1px lightgray, 1px 0 1px 1px lightgray; */
  border-radius: 5px;
  max-width: 400px;
}

#chatPopup1 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 95%;
  /* other styles... */
}



#chatHeader {
  background-color: #003C71;
  color: white;
  padding: 10px;
  display: flex;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  justify-content: space-between;
  align-items: center;

}

#openChatBtn {
  position: fixed;
  bottom: 20px;
  right: 10px;
  border: 0px;
  background: transparent;
}

#openChatBtn1 {
  position: fixed;
  bottom: 81px;
  right: 0px;
  border: 0px;
  background: transparent;
}

#openChatBtn1>img {
  width: 50px;
}

#openChatBtn>img {
  width: 50px;
}

#closeChatBtn {
  cursor: pointer;
  font-size: 30px;
}

#chatBody {
  padding: 10px;
  height: 470px;
  /* max-height: 400px; */
  overflow-y: auto;
  background: rgb(229, 242, 247);
}

#chatFooter {
  display: flex;
  padding: 10px;
  background: rgb(229, 242, 247);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#messageInput {
  flex: 1;
  margin-right: 10px;
}

#sendBtn {
  cursor: pointer;
}

.chat {
  /* margin: 0 0 1rem 0; */
  /* padding: 2rem 3rem; */
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  text-align: justify;
}

.chatimg {
  object-fit: cover;
  width: 40px;
  margin-right: 2%;
  /* margin-top: 1%; */
  border-radius: 30px;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in-button {
  animation: fadeIn 0.5s ease-in-out;
}

.fade-in-popup {
  animation: fadeIn 0.5s ease-in-out;
}

/* chat popup end */


.selected {
  color: green;
  background-color: #003C71;
  border: 1px solid #003C71;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.selected:hover {
  color: white;
  background-color: #003C71;
  border: 1px solid #003C71;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.selected.active {
  color: white;
  background-color: #003C71;
  border: 1px solid #003C71;
  padding: 10px 10px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* searching */
.suggestions-list {
  /* max-height: 200px; */
  overflow-y: auto;
  background-color: white;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.suggestion-item {
  cursor: pointer;
  padding: 10px;
}

.suggestion-item:hover {
  background-color: #f8f9fa;
}

form .suggestions-list {
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-top: none;
  margin-top: 0;
  border-radius: 0 0 5px 5px;
}

form .suggestions-list.show {
  opacity: 1;
}

form .suggestions-list.hide {
  opacity: 0;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
