/* Apply collapsible behavior only for mobile devices */



.p1
{
    color: white;
    text-align: justify;
    line-height: 30px;
}
.footer_title
{
    font-size: 25px;
    text-transform: capitalize;
    color: whitesmoke;
    margin-top: 10%;
    margin-bottom: 5%;
    font-weight: 600;
}
.my_list ul li 
{
    list-style-type: none;
    margin-top: 10px;
}
.my_list ul li a
{
    text-decoration: none;
    text-transform: capitalize;
    color: white;
    font-weight: 600;

}



  