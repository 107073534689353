.cards-container {
    background-color: #f8f9fa;
    /* padding: 30px; */
    padding-top: 50px;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .card {
    border: none;
    transition: transform 0.3s;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-img-top {
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
  
  .card-title {
    color: #003C71;
    font-weight: bold;
  }
  
  .card-text {
    color: #6c757d;
  }
  
  .btn-primary {
    background-color: #22a0bf;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #18959e;
  }
  