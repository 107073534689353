.service-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 30px;
  }
  
  .service-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    height: 300px; /* Set a fixed height for uniformity */
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-icon {
    font-size: 40px;
    color: #22a0bf;
  }
  
  h5 {
    font-size: 18px;
    font-weight: bold;
  }
  
  p {
    font-size: 14px;
    color: #6c757d;
  }
  