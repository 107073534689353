.navigation-menu {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.5rem;
}

.nav-item {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-item:hover,
.nav-item.active {
  color: gold;
}

.cart-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.badge {
  position: absolute;
  top: -8px;
  right: -10px;
  font-size: 0.7rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
}
